import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import loadable from '@loadable/component'
import { StaticImage } from "gatsby-plugin-image";

import useMedia from "@hooks/useMedia";

import colors from "@styles/colors";
import media from "@styles/media";
import text from "@styles/text";

const TextAnimation = loadable(() => import("@components/TextAnimation"));

type props = {
  loading: boolean;
};

const Solutions: React.FC<props> = ({ loading }) => {
  const contentRef = useRef(null);
  const wrapperRef = useRef(null);

  const [triggerTitle, setTriggerTitle] = useState(false);
  const [triggerTiles, setTriggerTiles] = useState(false);

  useEffect(() => {

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "top-=60% top",
        },
      });
      tl.to(contentRef.current, {
        duration: 0.2,
        width: "100%",
        onComplete: () => {
          setTriggerTitle(true);
        },
      });

      return () => {
        tl.kill();
      };

  }, []);

  return (
    <Wrapper id="solutions-section" ref={wrapperRef}>
      <Background>
        <StaticImage
          src={"../../images/png/accentBG.png"}
          alt="gradient"
          placeholder="blurred"
          style={{width: '100%', height: '100%'}}
        />
      </Background>
      <Content ref={contentRef}>
        <Title>
          <TextAnimation
            textArray={[() => "Solutions designed", () => "to impact"]}
            className="solutions-title"
            trigger={triggerTitle}
            height={useMedia("5.556vw", "5.556vw", "7.194vw", "9.600vw")}
            callback={() => setTriggerTiles(true)}
          />
        </Title>
        <TileContainer>
          <TileWrapper>
            <TileTitle>
              <TextAnimation
                textArray={[() => "Efficiency"]}
                className="tile-1-title"
                height={useMedia("1.736vw", "1.736vw", "2.638vw", "6.400vw")}
                trigger={triggerTiles}
              />
            </TileTitle>
            <TileText>
              <TextAnimation
                textArray={[
                  () =>
                    "With instant analytics reporting for your operation, you can leverage information to make more strategic decisions.",
                ]}
                className="tile-1-text"
                height={useMedia("6.944vw", "6.944vw", "16.547vw", "26.667vw")}
                trigger={triggerTiles}
              />
            </TileText>
          </TileWrapper>
          <TileWrapper>
            <TileTitle>
              <TextAnimation
                textArray={[() => "Security"]}
                className="tile-2-title"
                height={useMedia("1.736vw", "1.736vw", "2.638vw", "6.400vw")}
                trigger={triggerTiles}
              />
            </TileTitle>
            <TileText>
              <TextAnimation
                textArray={[
                  () =>
                    "Eliminate risks with complete, secure data and analytics. Protected from both pipeline breeches and data breeches, our solutions focus on safety at every stage of the process.",
                ]}
                className="tile-2-text"
                height={useMedia("10.417vw", "10.417vw", "19.305vw", "40vw")}
                trigger={triggerTiles}
              />
            </TileText>
          </TileWrapper>
          <TileWrapper>
            <TileTitle>
              <TextAnimation
                textArray={[() => "Revenue"]}
                className="tile-3-title"
                height={useMedia("1.736vw", "1.736vw", "2.638vw", "6.400vw")}
                trigger={triggerTiles}
              />
            </TileTitle>
            <TileText>
              <TextAnimation
                textArray={[
                  () =>
                    "Reduce the number of leaks, minimize the cost of mitigation, and save money on servicing your pipeline. Our Solutions are the most cutting-edge and cost-efficient on the market.",
                ]}
                className="tile-3-text"
                height={useMedia(
                  "12.153vw",
                  "12.153vw",
                  "22.062vw",
                  "46.667vw"
                )}
                trigger={triggerTiles}
              />
            </TileText>
          </TileWrapper>
          <TileWrapper>
            <TileTitle>
              <TextAnimation
                textArray={[() => "Digital monitoring"]}
                className="tile-4-title"
                height={useMedia("1.736vw", "1.736vw", "2.638vw", "6.400vw")}
                trigger={triggerTiles}
              />
            </TileTitle>
            <TileText>
              <TextAnimation
                textArray={[
                  () =>
                    "Customizable dashboards provide instant, precise reporting of data secured using proven methods and practices, along with multiple firewalls to prevent cross contamination and breeches.",
                ]}
                className="tile-4-text"
                height={useMedia(
                  "12.153vw",
                  "12.153vw",
                  "22.062vw",
                  "46.667vw"
                )}
                trigger={triggerTiles}
              />
            </TileText>
          </TileWrapper>
          <TileWrapper>
            <TileTitle>
              <TextAnimation
                textArray={[() => "Deep data analysis"]}
                className="tile-5-title"
                height={useMedia("1.736vw", "1.736vw", "2.638vw", "6.400vw")}
                trigger={triggerTiles}
              />
            </TileTitle>
            <TileText>
              <TextAnimation
                textArray={[
                  () =>
                    "Our powerful analytics computing provides wide and deep data analysis, equipping your team with insights that  enable faster and more strategic decision-making.",
                ]}
                className="tile-5-text"
                height={useMedia("10vw", "10vw", "24.820vw", "40vw")}
                trigger={triggerTiles}
              />
            </TileText>
          </TileWrapper>
        </TileContainer>
      </Content>
    </Wrapper>
  );
};

export default Solutions;

const TileWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${media.fullWidth} {
    margin-right: 6.806vw;
    margin-bottom: 3.472vw;
    width: 16.667vw;
  }

  ${media.desktop} {
    margin-right: 6.806vw;
    margin-bottom: 3.472vw;
    width: 16.667vw;
  }

  ${media.tablet} {
    margin-right: 8.633vw;
    margin-bottom: 7.194vw;
    width: 25.18vw;
  }

  ${media.mobile} {
    margin-right: 0vw;
    margin-bottom: 14.667vw;
    width: 63.467vw;
  }
`;

const TileTitle = styled.h3`
  color: ${colors.keppel100};
  display: flex;

  ${media.fullWidth} {
    ${text.desktopPetiteHeading}
    margin-bottom: 2.222vw;
  }

  ${media.desktop} {
    ${text.desktopPetiteHeading}
    margin-bottom: 2.222vw;
  }

  ${media.tablet} {
    ${text.tabletPetiteHeading}
    margin-bottom: 3.837vw;
  }

  ${media.mobile} {
    ${text.mobilePetiteHeading}
    margin-bottom: 8.533vw;
  }
`;

const TileText = styled.p`
  color: ${colors.culturedWhite60};
  display: flex;

  ${media.fullWidth} {
    ${text.desktopBodyCopy1}
  }

  ${media.desktop} {
    ${text.desktopBodyCopy1}
  }

  ${media.tablet} {
    ${text.tabletBodyCopy1}
  }

  ${media.mobile} {
    ${text.mobileBodyCopy1}
  }
`;

const Wrapper = styled.section`
  height: 100%;
  width: 100%;
  background-color: ${colors.black};
  position: relative;
  box-sizing: border-box;
  overflow: hidden;

  ${media.fullWidth} {
    padding-left: 11.319vw;
    padding-right: 11.319vw;
    padding-top: 9.653vw;
    padding-bottom: 5.556vw;
  }

  ${media.desktop} {
    padding-left: 11.319vw;
    padding-right: 11.319vw;
    padding-top: 9.653vw;
    padding-bottom: 5.556vw;
  }

  ${media.tablet} {
    padding-left: 5.995vw;
    padding-right: 5.995vw;
    padding-top: 19.185vw;
    padding-bottom: 14.388vw;
  }

  ${media.mobile} {
    padding-left: 6.667vw;
    padding-right: 6.667vw;
    padding-top: 26.667vw;
    padding-bottom: 9.6vw;
  }
`;

const Content = styled.div`
  width: 70%;
  border-top: 1px solid ${colors.culturedWhite60};
  position: relative;
  z-index: 2;

  ${media.fullWidth} {
    padding-top: 2.222vw;
  }

  ${media.desktop} {
    padding-top: 2.222vw;
  }

  ${media.tablet} {
    padding-top: 3.837vw;
  }

  ${media.mobile} {
    padding-top: 5.333vw;
  }
`;

const Background = styled.div`
  position: absolute;
  transform: rotate(152deg);
  opacity: 0.4;

  ${media.fullWidth} {
    width: 96vw;
    height: 96vw;
    right: -30.556vw;
    top: -6.736vw;
  }

  ${media.desktop} {
    width: 96vw;
    height: 96vw;
    right: -30.556vw;
    top: -6.736vw;
  }

  ${media.tablet} {
    width: 114.628vw;
    height: 114.628vw;
    top: 33.189vw;
    left: 20.283vw;
  }

  ${media.mobile} {
    width: 250vw;
    height: 250vw;
    top: 101.189vw;
    left: -49.717vw;
  }
`;

const Title = styled.h2`
  color: ${colors.culturedWhite60};
  display: flex;
  flex-direction: column;

  ${media.desktop} {
    ${text.desktopXLHeading}
    width: 47.431vw;
    margin-bottom: 7.917vw;
  }

  ${media.fullWidth} {
    ${text.desktopXLHeading}
    width: 47.431vw;
    margin-bottom: 7.917vw;
  }

  ${media.tablet} {
    ${text.tabletMHeading}
    width: 81.894vw;
    margin-bottom: 9.592vw;
  }

  ${media.mobile} {
    ${text.mobileXSHeading}
    margin-bottom: 16vw;
  }
`;

const TileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${media.fullWidth} {
    width: 70.833vw;
  }

  ${media.desktop} {
    width: 70.833vw;
  }

  ${media.tablet} {
    width: 100%;
  }
`;
